@import "../../../../variables.scss";

.redirectDetailsWrap {
  font-size: 20px;
  text-align: center;
  width: 100%;
  top: calc(50% + 4rem);
  @include center();
  @include rwd(small) {
    top: calc(50% + 2.5rem);
  }
  @media (max-height: 445px) {
    position: static;
    transform: none;
    margin-top: 7rem;
  }
  @media (min-width: 740px) and (max-height: 570px) {
    position: static;
    transform: none;
    margin-top: 10rem;
  }
  .loader {
    display: inline-flex;
  }
  .message {
    span,
    img {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-size: 3.5rem;
      margin-right: 1.5rem;
      @include rwd(small) {
        font-size: 2.6rem;
        margin-right: 0;
        display: block;
      }
    }
    img {
      width: auto;
      height: 5rem;
      @include rwd(small) {
        height: 4rem;
        margin-top: 1rem;
      }
    }
  }
  .loading {
    margin: 5rem 0 8rem;
    @include rwd(small) {
      margin: 3rem 0;
    }
    span,
    img {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-size: 2.6rem;
      margin-right: 1.5rem;
      @include rwd(small) {
        font-size: 2rem;
      }
    }
    img {
      width: 3.5rem;
      height: 3.5rem;
      @include rwd(small) {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  .text {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    @include rwd(small) {
      font-size: 1.7rem;
      margin-bottom: 1rem;
    }
  }
  .fareOverview {
    border-color: $color-main;
    border-style: solid;
    border-width: 1px 1px 5px 1px;
    padding: 0.5rem 1rem;
    width: 100%;
    max-width: 60rem;
    display: inline-block;
    text-align: left;
    @include rwd(maxfare) {
      border-width: 1px 0;
      background-color: $color-main-very-light;
    }
    @include rwd(maxnarrow) {
      max-width: 40rem;
    }
  }
}
