$color-main: #ed0087;
$color-main-very-light: #e8f9fe;
$color-main-light: #daf7fe;
$color-main-dark: #00c2f6;
$color-active: #00aeef;
$color-text-gray: #4d4d4d;
$color-text-light-gray: #8f96a3;
$color-white: #ffffff;
$color-black: #000000;
$color-very-light: #f0f0f0;
$color-light: #e4e5e9;
$color-dark: #65696a;
$color-very-dark: #49586f;
$color-gray: #b9bdc6;
$color-fb: #3853a1;
$color-apla: rgba(0, 0, 0, 0.5);
$color-invalid: #ff4a55;
$color-price: #14ae00;
$color-brand: #f4f4f4;
$color-action: #00339a;
$color-warning: #ff9500;

$rwd-fare: 410px;
$rwd-datepicker: 495px;
$rwd-medium: 576px;
$rwd-xmedium: 768px;
$rwd-narrow: 992px;
$rwd-wide: 1200px;

$fields-height: 3.4rem;

$border-radius: 0.4rem;

$zindex: (
  modal: 600,
  overlay: 500,
  sticky: 400,
  dropdown: 300,
  header: 200,
  footer: 100,
  base: 0,
);

@function unit($size: 1) {
  @return $size * 1.6 + rem;
}

@function zindex($layer) {
  @if (map-has-key($zindex, $layer)) {
    @return map-get($zindex, $layer);
  } @else {
    @warn "Missing key in z-index space.";
    @return false;
  }
}

@mixin clearfix {
  &:after {
    display: block;
    content: '';
    clear: both;
  }
}

@mixin fontsize($size: 0) {
  @if ($size == 0) {
    font-size: 1.6rem;
  } @else {
    font-size: $size + rem;
  }
}

@mixin page() {
  margin: 0 auto;
  width: 100%;
  max-width: 1112px;
}

@mixin rwd($media) {
  @if $media == small {
    @media (max-width: $rwd-medium - 1) {
      @content;
    }
  } @else if $media == datepicker {
    @media (max-width: $rwd-datepicker) {
      @content;
    }
  } @else if $media == medium {
    @media (min-width: $rwd-medium) {
      @content;
    }
  } @else if $media == xmedium {
    @media (min-width: $rwd-xmedium) {
      @content;
    }
  } @else if $media == narrow {
    @media (min-width: $rwd-narrow) {
      @content;
    }
  } @else if $media == maxnarrow {
    @media (max-width: $rwd-narrow - 1) {
      @content;
    }
  } @else if $media == wide {
    @media (min-width: $rwd-wide) {
      @content;
    }
  } @else if $media == fare {
    @media (min-width: $rwd-fare) {
      @content;
    }
  } @else if $media == maxfare {
    @media (max-width: $rwd-fare - 1) {
      @content;
    }
  }
}

@mixin cover($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin radius() {
  border-radius: $border_radius;
}

@mixin noselect() {
  user-select: none;
}

@mixin inline-block($verticalAlign: middle) {
  display: inline-block;
  vertical-align: $verticalAlign;
}

@mixin button($width: 100%, $backgroundColor: $color-main) {
  width: $width;
  display: inline-block;
  border: none;
  outline: none;
  background-color: $backgroundColor;
  color: #fff;
  text-align: center;
  padding: 0;
  cursor: pointer;
  line-height: 3.6rem;
  &:hover {
    filter: brightness(0.9);
  }
}

@mixin center($axis: 'both') {
  position: absolute;
  @if ($axis == 'x') {
    left: 50%;
    transform: translateX(-50%);
  }
  @if ($axis == 'y') {
    top: 50%;
    transform: translateY(-50%);
  }
  @if ($axis == 'both') {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@mixin ballon() {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: zindex(dropdown);
  background-color: #fff;
  // &:before {
  //   display: block;
  //   content: "";
  //   width: 2rem;
  //   height: 2rem;
  //   border: 1px solid #555;
  //   transform: translateY(-50%) rotate(45deg);
  //   position: absolute;
  //   top: 0;
  //   left: 3rem;
  //   background-color: #fff;
  //   box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  // }
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ballonIn() {
  position: relative;
  padding: 1rem;
  z-index: zindex(dropdown) + 3;
}

@mixin arrow-line($size: 1rem, $color: #000) {
  &:before {
    display: block;
    content: '';
    width: $size;
    height: $size;
    border-width: 2px;
    border-style: solid solid none none;
    border-color: $color;
    transform: rotate(45deg);
    position: absolute;
  }
}

@mixin arrow-fill($color: #000, $size: 0.4rem) {
  &:before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-width: $size;
    border-style: solid;
    border-color: $color $color transparent transparent;
    transform: scaleX(0.7) rotate(135deg);
    position: absolute;
  }
}

@mixin arrow-fill-up() {
  &:before {
    transform: translateY(7px) scaleX(0.7) rotate(315deg);
  }
}

@mixin close($color: #000, $size: 1rem) {
  &:before,
  &:after {
    display: block;
    content: '';
    width: $size;
    height: 0.2rem;
    background-color: $color;
    @include center('y');
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

@mixin invalid() {
  // border-color: $color-invalid;
}

@mixin arrow-right() {
  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 98%;
    height: 0.2rem;
    background-color: #000;
    top: 50%;
    transform: translateY(-50%);
  }
  @include arrow-fill();
  &:before {
    transform: translateY(-50%) scaleY(0.6) scaleX(1) rotate(45deg);
    right: 0;
    top: 50%;
  }
}
